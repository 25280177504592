import { CardActionAreaProps } from '@mui/material/CardActionArea'
import Stack from '@mui/material/Stack'
import React, { PropsWithChildren } from 'react'
import { RcButton } from './RcButton'

export interface RcPaperButtonProps extends CardActionAreaProps {
  onClick?: () => void
  disabled?: boolean
  selected?: boolean
}

export const RcPaperButton: React.FC<PropsWithChildren<RcPaperButtonProps>> = ({
  children,
  onClick,
  disabled,
  selected
}) => {
  const canActivate = !!onClick && !disabled

  return (
    <RcButton
      variant='outlined'
      onClick={onClick}
      disabled={!canActivate}
      fullWidth
      sx={{
        pt: 3,
        pb: 2,
        height: '100%',
        borderRadius: 5,
        borderColor: selected ? 'primary.main' : 'text.secondary'
      }}
    >
      <Stack direction='column' spacing={2} width='100%' alignItems='center'>
        {children}
      </Stack>
    </RcButton>
  )
}
