'use client'

import React, { PropsWithChildren, Suspense } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { RcLoader } from './RcLoader'
import { ErrorFallback } from '@/components/organisms/site/ErrorFallback'

export type RcSuspenseProps = PropsWithChildren<{
  skipLoader?: boolean
  skipError?: boolean
  height?: number | string
  borderRadius?: number | string
}>

export const RcSuspense: React.FC<RcSuspenseProps> = ({
  skipLoader,
  skipError,
  height,
  borderRadius,
  children
}) => {
  return (
    <ErrorBoundary FallbackComponent={skipError ? Noop : ErrorFallback}>
      <Suspense
        fallback={
          skipLoader ? null : (
            <RcLoader height={height} borderRadius={borderRadius} />
          )
        }
      >
        {children}
      </Suspense>
    </ErrorBoundary>
  )
}

const Noop = ({}: FallbackProps) => {
  return <></>
}
